import ALL_DEPARTMENTS from '@/graphql/departments/query/getAllDepartmentsAutocomplete.gql'

const departments = ref([])
const key = ref(0)
const firstTime = ref(true)

export async function departmentsAutocomplete(params: any) {
  const apolloQuery = ALL_DEPARTMENTS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: getAuthUser()?.value?.language?.toLowerCase() === 'en'
          ? 'NAME_EN'
          : getAuthUser()?.value?.language?.toLowerCase() === 'es'
            ? 'NAME_ES'
            : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
              ? 'NAME_FR'
              : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? 'NAME_DE'
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                  ? 'NAME_SV'
                  : 'NAME_AR',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchDepartmentsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await departmentsAutocomplete({ search: queryString })
    cb(results?.getAllDepartments?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchDepartmentsCb')
  }
}

export function getSearchDepartments(entityDepartment: any, entityDepartments: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchDepartments('', entityDepartment, entityDepartments)
  }
  return departments?.value ?? []
}

export async function searchDepartments(queryString: any, entityDepartment: any, entityDepartments: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await departmentsAutocomplete({ search: queryString })
    const remoteDepartments = results?.getAllDepartments?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []
    const departmentsToAdd: { label: any; value: any }[] = []
    entityDepartments?.forEach((j: any) => {
      if (!remoteDepartments.find((k: any) => k.value === j.uuid)) {
        departmentsToAdd.push({
          label: j.name,
          value: j.uuid
        })
      }
    })
    if (entityDepartment && !remoteDepartments.find((k: any) => k.value === entityDepartment.uuid)) {
      departmentsToAdd.push({
        label: entityDepartment.name,
        value: entityDepartment.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    departments.value = [...remoteDepartments, ...departmentsToAdd]

    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchDepartments')
  }
}

export function getDepartmentKey () {
  return key.value
}

export function initialDepartments () {
  departments.value = []
  key.value = 0
  firstTime.value = true
}